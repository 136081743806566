import React, { Component } from "react";

class NavBlog extends Component {
  render() {
    return (
      <div>
      {window.open('https://blog.shivamdongre.in', '_blank', 'noreferrer')}
      {window.location.assign('/')}
      </div>
    );
  }
}

export default NavBlog;
